import React from "react";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import axios from "axios";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Button } from "./styles";
import { API } from "../../../config/api"


export default function PdfColecao({ num }) {
  const token = sessionStorage.getItem("token");
  const email = sessionStorage.getItem("email");
  const handlePdf = async () => {


    try {
      toast.success("Aguarde seu PDF está sendo gerado.");
      const req = await axios.get(
        `${API.external_imagens}?item_id=${num}&type=catalogo`,
        {

          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        },
      );


      if (req?.data?.data?.url) {
        console.log(req.data.data)
        //const file = new Blob([req.data], { type: "application/pdf" });
      //  const fileURL = URL.createObjectURL(req.data.data.url);
        window.open(req.data.data.url);
      }
      else {
        toast.error("Não foi possível obter o catálogo");

      }
    } catch (err) {

      toast.error("Não foi possível obter seu PDF");
    }
  };
  return (
    <Button type="button" onClick={(e) => handlePdf(e)}>

      <PictureAsPdfIcon />
    </Button>
  );
}


PdfColecao.propTypes = {
  num: PropTypes.number.isRequired
};

