import React, { useState } from "react";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import axios from "axios";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Button,  } from "./styles"; // Importe o componente de loading, se necessário
import  Loading from "../../Loading"
import { API } from "../../../config/api";

export default function Pdf({ num }) {
  const [loading, setLoading] = useState(false);
  const token = sessionStorage.getItem("token");
  const email = sessionStorage.getItem("email");

  const handlePdl = async () => {
    try {
      setLoading(true); // Ativar o loading
      
      toast.success("Aguarde seu PDF está sendo gerado.");

      const req = await axios.get(
        `${API.impressos_pedidos}/${num}/?email=${email}`,
        {
          responseType: "arraybuffer",

          headers: {
            "x-access-token": token,
          },
        }
      );

       const file = new Blob([req.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (err) {
      toast.error("Não foi possível gerar seu PDF");
    } finally {
      setLoading(false); // Desativar o loading, independentemente do resultado
    }
  };

  return (
    <>
      <Button type="button" onClick={() => handlePdl()}>
      {loading && (
                  <i
                    className="fa fa-refresh fa-spin"
                    style={{ marginRight: '5px' }}
                  />
                )}
                {!loading &&  <PictureAsPdfIcon />}

       
      </Button>
      {loading && <Loading />} {/* Renderizar o componente de loading enquanto o PDF está sendo gerado */}
    </>
  );
}

Pdf.propTypes = {
  num: PropTypes.number.isRequired,
};
